<template>
  <div
    class="timeseries"
    id="searchBar"
    style="height: 100%; padding-top: 88px"
    :style="{ paddingBottom: showLoading == false ? '0px' : '64px' }"
  >
    <div class="head clear shaow" style="margin-top: -88px; position: relative">
      <div class="head_top clear">
        <div class="head_item cleft">
          <div class="headlogo">
            <router-link to="/Home"
              ><img src="/img/epslogo.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="headsearch cleft">
          <div class="searchbox">
            <a-popover
              :visible="recVisible||recVisible2"
              trigger="focus"
              placement="bottomLeft"
            >
              <input
                type="text"
                id="inputWrap"
                v-model="urlName"
                @keyup="getInput($event)"
                @input="getRecommendIndicator"
              />
                <!-- @focus="getHistory" -->
                <!-- @blur="hideHistory" -->
              <div slot="content" class="search-rec">
                <a-menu v-show="recVisible">
                  <a-menu-item
                    v-for="(item, key) in recList"
                    :key="key"
                    @click="selectKeyword(item.indicatorName)"
                    >{{ item.indicatorName }}</a-menu-item
                  >
                </a-menu>
                <div v-show="recVisible2">
                    <div class="searchText" v-show="historyList.length!==0">
                      <div class="title">搜索历史<span @click="alertTip"><a-icon type="delete"></a-icon></span></div>
                      <ul>
                        <li v-for="(item,index) in historyList" :key="index">{{item}}</li>
                      </ul>
                    </div>
                    <div class="searchText" v-show="recommendList.length!==0">
                      <a-col></a-col>
                      <div class="title">搜索发现</div>
                      <ul>
                        <li v-for="(item,index) in recommendList" :key="index">{{item}}</li>
                      </ul>
                    </div>
                </div>
              </div>
            </a-popover>
            <div class="seabtn" @click="getSearchBtn()">
              <i class="gwicon searchs"></i>
            </div>
          </div>
        </div>
        <div class="head_group cright">
          <ul class="headlink cright">
            <li>
              <a
                href="https://a8-im.7x24cc.com/phone_webChat.html?accountId=N000000050896&chatId=11563011-8e54-438d-a271-36964839cd53"
                target="_blank"
                >在线咨询</a
              >
            </li>
            <li>
              <a href="http://www.epschinadata.com/" target="_blank">English</a>
            </li>
          </ul>
          <!-- <div class="headName cright" v-show="userObj.groupName">欢迎您，<span>{{userObj.groupName}}</span></div> -->
        </div>
      </div>
    </div>
    <div style="min-height: 100%; width: 100%; backgroup: #fdfdfd">
      <div class="containers mrg20" v-show="showLoading == false">
        <div class="screenGroup cleft">
          <!-- 频度 -->
          <div class="screenSelect">
            <h4 class="titleSelect" @click="yearFlag = !yearFlag">频度筛选</h4>
            <ul
              class="listSelect"
              :class="yearFlag == false ? 'highid' : ''"
              v-if="yearlist.length > 5"
            >
              <li
                class=""
                :class="yearCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in yearlist"
                :key="k"
                @click="getYear(v)"
              >
                <div class="titname cleft">
                  <span class="titles">{{ v.name }}</span>
                  <span class="numbers">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="yearCurrent == v.name"
                  @click.stop="getYearRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
            <ul class="listSelect" v-else>
              <li
                class=""
                :class="yearCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in yearlist"
                :key="k"
                @click="getYear(v)"
              >
                <div class="titname cleft">
                  <span class="titles">{{ v.name }}</span>
                  <span class="numbers">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="yearCurrent == v.name"
                  @click.stop="getYearRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
          </div>
          <!-- 数据时间 -->
          <div class="screenSelect">
            <h4 class="titleSelect" @click="dataTimeFlag = !dataTimeFlag">
              数据时间
              <i
                class="gwicon screens"
                v-if="datatimelist.length > 5"
              ></i>
            </h4>
            <ul
              class="listSelect"
              :class="dataTimeFlag == false ? 'highid' : ''"
              v-if="datatimelist.length > 5"
            >
              <li
                class="clear"
                :class="dataTimeCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in datatimelist"
                :key="k"
                @click="getDataTime(v)"
              >
                <div class="titname cleft">
                  <span class="titles">{{ v.name }}</span>
                  <span class="numbers">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="dataTimeCurrent == v.name"
                  @click.stop="getDataTimeRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
            <ul class="listSelect" v-else>
              <li
                class="clear"
                :class="dataTimeCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in datatimelist"
                :key="k"
                @click="getDataTime(v)"
              >
                <div class="titname cleft">
                  <span class="titles">{{ v.name }}</span>
                  <span class="numbers">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="dataTimeCurrent == v.name"
                  @click.stop="getDataTimeRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
          </div>
          <!-- 区域 -->
          <div class="screenSelect">
            <h4 class="titleSelect" @click="areaFlag = !areaFlag">
              区域筛选
              <i
                class="gwicon screens"
                v-if="arealist.length > 5"
              ></i>
            </h4>
            <ul
              class="listSelect"
              :class="areaFlag == false ? 'highid' : ''"
              v-if="arealist.length > 5"
            >
              <li
                class="clear"
                :class="areaCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in arealist"
                :key="k"
                @click="getArea(v)"
              >
                <div class="titname cleft">
                  <span class="titles">{{ v.name }}</span>
                  <span class="numbers">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="areaCurrent == v.name"
                  @click.stop="getAreaRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
            <ul
                class="listSelect" 
                v-else
              >
              <li
                class="clear"
                :class="areaCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in arealist"
                :key="k"
                @click="getArea(v)"
              >
                <div class="titname cleft">
                  <span class="titles">{{ v.name }}</span>
                  <span class="numbers">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="areaCurrent == v.name"
                  @click.stop="getAreaRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
          </div>
          <!-- 国民经济分类 -->
          <!-- <div class="screenSelect" v-show="orderlist.length > 0">
            <h4 class="titleSelect" @click="orderFlag = !orderFlag">
              按国民经济行业分类
            </h4>
            <ul
              class="listSelect"
              :class="orderFlag == false ? 'highid' : ''"
              v-if="orderlist.length > 5"
            >
              <li
                class="clear"
                :class="orderCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in orderlist"
                :key="k"
                @click="getOrder(v)"
              >
                <div class="titname cleft">
                  <span class="titles" v-if="v.name == 0">否</span>
                  <span class="titles" v-else>是</span>
                  <span class="numbers">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="orderCurrent == v.name"
                  @click.stop="getOrderRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
            <ul class="listSelect" v-else>
              <li
                class="clear"
                :class="orderCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in orderlist"
                :key="k"
                @click="getOrder(v)"
              >
                <div class="titname cleft">
                  <span class="titles" v-if="v.name == 0">否</span>
                  <span class="titles" v-else>是</span>
                  <span class="numbers">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="orderCurrent == v.name"
                  @click.stop="getOrderRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
          </div> -->
          <!--新加数据库筛选-->
          <div class="screenSelect" v-show="cubeAgg.length>0">
            <h4 class="titleSelect" @click="cubeFlag = !cubeFlag">
              数据库筛选
              <i
                class="gwicon screens"
                v-if="datatimelist.length > 5"
              ></i>
            </h4>
            <ul
              class="listSelect"
              :class="cubeFlag == false ? 'highid' : ''"
              v-if="cubeAgg.length > 5"
            >
              <li
                class="clear"
                :class="cubeCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in cubeAgg"
                :key="k"
                @click="getCube(v)"
              >
                <div class="titname cleft">
                  <span class="titles" :title="v.name">{{v.name}}</span>
                  <span class="numbers">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="cubeCurrent == v.name"
                  @click.stop="getCubeRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
            <ul class="listSelect" v-else>
              <li
                class="clear"
                :class="cubeCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in cubeAgg"
                :key="k"
                @click="getCube(v)"
              >
                <div class="titname cleft">
                  <span class="titles">{{v.name}}</span>
                  <span class="numbers">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="cubeCurrent == v.name"
                  @click.stop="getCubeRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
          </div>
          <!-- 数据来源 -->
          <div class="screenSelect">
            <h4 class="titleSelect" @click="sourceFlag = !sourceFlag">
              来源筛选
              <i class="gwicon screens" v-show="sourcelist.length > 5"></i>
            </h4>
            <ul
              class="listSelect"
              :class="sourceFlag == true ? 'highid' : ''"
              v-if="sourcelist.length > 5"
            >
              <li
                class="clear"
                :class="sourceCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in sourcelist"
                :key="k"
                @click="getSource(v)"
              >
                <div class="titname cleft">
                  <span class="titles_tit" :title="v.name">{{
                    v.name
                  }}</span>
                  <span class="numbers_tit">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="sourceCurrent == v.name"
                  @click.stop="getSourceRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
            <ul class="listSelect" v-else>
              <li
                class="clear"
                :class="sourceCurrent == v.name ? 'current' : ''"
                v-for="(v, k) in sourcelist"
                :key="k"
                @click="getSource(v)"
              >
                <div class="titname cleft">
                  <span class="titles_tit" :title="v.name">{{
                    v.name
                  }}</span>
                  <span class="numbers_tit">({{ v.val }})</span>
                </div>
                <div
                  class="clicks cright"
                  v-show="sourceCurrent == v.name"
                  @click.stop="getSourceRemove"
                >
                  &times;
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="contenFroup cright">
          <div class="screenTtile" :class="{ fixtop: isScollTable }">
            <div class="div cleft">
              共检索到&nbsp;<span class="check_num">{{ allSize }}</span
              >&nbsp;条记录
            </div>
          </div>
          <div class="screenTable">
            <i-col class="demo-spin-col" v-if="loader">
              <Spin fix>加载中...</Spin>
            </i-col>
            <div class="tabListGroup" v-else>
                  <div
                    class="resItem"
                    v-show="timesLists.length > 0"
                    v-for="(item, k) in timesLists"
                    :key="k"
                  >
                    <span style="color:#333;cursor: pointer;" @click="check(item)" v-html="item.indicatorName"></span><br>
                    <span class="allName">全称：
                      <span :title="item.indicatorFName" v-if="item.indicatorFName">{{item.indicatorFName}};</span>
                      <span :title="item.industryName" v-if="item.industryName">{{item.industryName}};</span>
                      <span :title="item.classifyName" v-if="item.classifyName">{{item.classifyName}};</span>
                      <span :title="item.commodityName" v-if="item.commodityName">{{item.commodityName}};</span>
                    </span>
                    <div class="lightColor">
                      <span class="regionName" v-show="item.regionName" :title="item.regionName|htmlToText(item.regionName)">区域：<span>{{item.regionName|htmlToText(item.regionName)}}</span></span>
                      <span class="freqName" v-show="item.freqName" :title="item.freqName">频度：{{item.freqName}}</span>
                      <span class="timeName" v-show="item.timeName" :title="item.timeName|htmlToText(item.timeName)">数据时间：<span>{{item.timeName|htmlToText(item.timeName)}}</span></span>
                      <span class="sourceName" v-show="item.sourceName" :title="item.sourceName">数据来源：{{item.sourceName}}</span>
                      <span class="cubeName" v-show="item.cubeName" :title="item.cubeName">所属数据库：{{item.cubeName}}</span>
                    </div>
                  </div>
              <i-col class="demo-spin-col" v-show="timesLists.length <= 0">
                <Spin fix>未查询到搜索结果数据</Spin>
              </i-col>
            </div>
          </div>
          <div
            class="screenPage"
            v-show="totalPage > pageSize && loader != true"
          >
            <Page
              style="display: inline-block; margin-left: 6px"
              :current="currentPage"
              :page-size="pageSize"
              :total="totalPage"
              @on-change="jumpPage"
            />
          </div>
        </div>
        <div
          v-if="btnFlag"
          style="cursor: pointer; position: fixed; right: 30px; bottom: 30px"
        >
          <div class="top_post" @click="backTop">
            <div class="pro_div">
              <i class="gwicon tops"></i>
            </div>
            <p class="proFont">返回</p>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <Footer></Footer>
    <div class="loading" v-show="showLoading == true">
      <div class="loadingInner"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import _ from 'lodash'
import Footer from "./commons/Footer.vue";
// import Modals from "./commons/Modals.vue";
import { getSessionItem, ajaxPost, jsonPost, urlEpsBak } from "../until/common";
export default {
  name: "Timeseries",
  components: {
    Footer,
    // Modals
  },
  data() {
    return {
      recVisible: false,
      recVisible2:false,
      historyList:[],
      recommendList:[],

      recList: [],
      btnLoading: false,
      showFonts: "",
      showLoading: false,
      loader: false,

      userObj: {},
      urlName: "",

      btnFlag: false,
      scrollTop: 0,

      currentCheck: 0,
      isScollTable: false,
      numCheck: 0,
      paramsCheck: {},
      sid: "",
      cubeId: "",
      errors: "*注意请选择同一个数据库条目来显示数据",
      searchName: "",

      pageSize: 0,
      currentPage: 1,
      totalPage: 0,
      allSize:'',

      // modalsFlag: false,
      // str: "",

      //频度筛选
      yearObj: {},
      yearCurrent: 0,
      yearFlag: false,
      yearlist: [],

      //数据时间
      dataTimeObj: {},
      dataTimeCurrent: 0,
      dataTimeFlag: false,
      datatimelist: [],

      //区域筛选
      areaObj: {},
      areaCurrent: 0,
      areaFlag: false,
      arealist: [],

      //按国民经济行业分类
      // orderObj: {},
      // orderCurrent: "-1",
      // orderFlag: false,
      // orderlist: [],

      //来源筛选
      sourceObj: {},
      sourceCurrent: 0,
      sourceFlag: false,
      sourcelist: [],

      //数据库筛选
      cubeObj:{},
      cubeAgg:[],
      cubeFlag:false,
      cubeCurrent:0,

      checkCode: "",
      timesCheck: false,
      timesLists: [],
    };
  },
  computed: {
    conditions() {
      return {
        //老的
        // cubeScope: 0, //数据库范围-(全部0 已开通1 未开通2)
        // keywords: this.searchName, //搜索关键词
        // page: this.currentPage, //当前页数
        // freqId: this.yearObj.freqId, //频度筛选-freqId
        // year: this.dataTimeObj.newDataYear ? this.dataTimeObj.newDataYear : "", //数据时间筛选-newDataYear
        // regclc: this.areaObj.regclc ? this.areaObj.regclc : "", //区域筛选-regclc
        // source: this.sourceObj.sourceZh ? this.sourceObj.sourceZh : "", //来源筛选-sourceZh
        // isIndustry: this.orderObj.isIndustry ? this.orderObj.isIndustry : "", //按国民经济行业分类-isIndustry(1是和0否)
        //检索升级 新的
        text: this.searchName, //搜索关键词
        currentPage: this.currentPage, //当前页数
        freqId: this.yearObj.id, //频度筛选-freqId
        regclcId: this.areaObj.id ? this.areaObj.id : "", //区域筛选-regclc
        cubeId: this.cubeObj.id, //数据库范围-(全部0 已开通1 未开通2)
        year: this.dataTimeObj.name ? this.dataTimeObj.name : "", //数据时间筛选-newDataYear
        // isIndustry: this.orderObj.isIndustry ? this.orderObj.isIndustry : "", //按国民经济行业分类-isIndustry(1是和0否)
        sourceName: this.sourceObj.name ? this.sourceObj.name : "", //来源筛选-sourceZh
      };
    },
  },
  filters:{
    htmlToText(htmlStr){
      if(!htmlStr) return
     return htmlStr.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
    },
  },
  watch: {
    conditions() {
      this.getInit();
    },
  },
  methods: {
    alertTip() {
      this.recVisible2=false;
      let that=this;
      this.$confirm({
        title: '删除搜索历史',
        content: '确定要删除全部搜索历史吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        centered:true,
        onOk() {
          axios({
            method: "get",
            url: "search/log/clearSearchHistory",
          }).then((res) => {
            if(res.data.success) {
               that.$Message.success('删除成功')
            }else{
               that.$Message.error('删除失败')
            }
          });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    //获取历史记录和搜索发现
    // getHistory(){
    //   this.recVisible2=true;
    //   axios({
    //     method: "get",
    //     url: "search/log/getGroupRecomKeywords",
    //   }).then((res) => {
    //     if(res.data.success) {
    //       this.historyList=res.data.list//历史记录
    //       this.recommendList=res.data.list//搜索发现
    //     }
    //   });
    // },
    //指标推荐接口
    getRecommendIndicator:_.debounce(function() {
      this.recVisible2=false;
      axios({
        method: "post",
        url: "search/recommendIndicator.do?keyword=" + this.urlName,
      }).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data.hits.length > 0) {
            this.recVisible = true;
            this.recList = res.data.data.hits.slice(0,10);
          }
        }
      });
    },1000),
    //选择推荐词
    selectKeyword(item) {
      this.urlName = item;
      this.recVisible = false;
      this.recList = [];
    },
    hideRec() {
      this.recVisible = false;
      this.recList = [];
    },
    // hideHistory() {
    //   this.recVisible2 = false;
    //   this.historyList = [];
    //   this.recommendList=[]
    // },
    getInput(event) {
      var that = this;
      const code = event.keyCode
        ? event.keyCode
        : event.which
        ? event.which
        : event.charCode;
      if (code == 13) {
        that.searchName = that.urlName;
        that.getInit();
      }
      that.hideRec()
    },
    getSearchBtn() {
      var that = this;
      that.searchName = that.urlName;
      that.getInit();
    },
    getInnerText(str) {
      return str
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, "")
        .replace(/<[^>]+?>/g, "")
        .replace(/\s+/g, " ")
        .replace(/ /g, " ")
        .replace(/>/g, " ");
    },
    getYear(item) {
      console.log(item,'Year');
      this.yearObj = item;
      this.yearCurrent = item.name;
      this.currentPage = 1;
      this.nodata();
      this.backTop();
    },
    getYearRemove() {
      this.yearCurrent = 0;
      this.yearObj = {};
    },
    getDataTime(item) {
      this.dataTimeObj = item;
      this.dataTimeCurrent = item.name;
      this.currentPage = 1;
      this.nodata();
      this.backTop();
    },
    getDataTimeRemove() {
      this.dataTimeCurrent = 0;
      this.dataTimeObj = {};
    },
    getArea(item) {
      console.log(item,'区域');
      this.areaObj = item;
      this.areaCurrent = item.name;
      this.currentPage = 1;
      this.nodata();
      this.backTop();
    },
    getAreaRemove() {
      this.areaCurrent = 0;
      this.areaObj = {};
    },
    getOrder(item) {
      console.log(item,'Order');
      this.orderObj = item;
      this.orderCurrent = item.name;
      this.currentPage = 1;
      this.nodata();
      this.backTop();
    },
      getOrderRemove() {
        this.orderCurrent = "-1";
        this.orderObj = {};
      },
    getCube(item){
      console.log(item,'cube');
      this.cubeObj=item;
      this.cubeCurrent = item.name;
      this.currentPage = 1;
      this.nodata();
      this.backTop();
    },
    getCubeRemove(){
      this.cubeCurrent = 0;
      this.cubeObj = {};
    },
    getSource(item) {
      console.log(item,'source');
      this.sourceObj = item;
      this.sourceCurrent = item.name;
      this.currentPage = 1;
      this.nodata();
      this.backTop();
    },
    getSourceRemove() {
      this.sourceCurrent = 0;
      this.sourceObj = {};
    },
    check(item) {
      var that = this;
      that.btnLoading = true;
      ajaxPost("getSearchDimensionsMode",{indicatorCode:item.indicatorCode,cubeId:item.cubeId}).then((res) => {
        if (res && res.data) {
          var setData = {
            type: 2,
            cubeId: item.cubeId,
            dims: res.data,
          };
          that.btnLoading = false;
          that.getBtn(setData)
        }
      });
    },
    getBtn(params) {
      var that = this;
      if (params.cubeId) {
        that.showLoading = true;
        jsonPost("getTranSave", params)
          .then((res) => {
            if (res && res.data) {
              window.open(urlEpsBak + "transform.html?key=" + res.data.key);
              that.showLoading = false;
            } else {
              alert("失败！");
            }
          })
          .catch(function (error) {
            window.console.log(error);
          });
      }
    },

    nodata() {
      var that = this;
      that.cubeId = null;
      that.numCheck = 0;
      that.paramsCheck = {};
    },
    jumpPage(current) {
      // 方法1
      // document.getElementById('searchBar').scrollTop = 0;
      // 方法2
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;
      var that = this;
      that.currentPage = current;
      that.nodata();
      that.backTop();
    },
    getInit() {
      var that = this;
      var params = {};
      for (var i in this.conditions) {
        if (this.conditions[i]) {
          params[i] = this.conditions[i];
        }
      }
      that.loader = true;
      that.timesLists = [];
      that.showLoading = true;
      axios({
        method: "post",
        url: "search/search/match",params,
      }).then((res) => {
            if(res.status==200){
              if(res.data.code==200){
                var origin={...res.data.data}
                if(origin){
                  console.log(origin);
                  //频度下年度
                  that.yearlist =origin.freqList
                  //数据来源
                  that.sourcelist=origin.sourceList
                  //区域
                  that.arealist =origin.regclcList
                  //最新时间
                  that.datatimelist =origin.newDataYearList
                  //按国民经济行业分类
                  // that.orderlist=origin.industryList
                  //数据库筛选
                  that.cubeAgg=origin.cubeList
                  //列表展示
                  that.timesLists=origin.hits
                  // 分页
                  that.currentPage = origin.page.currentPage?origin.page.currentPage:1;
                  that.totalPage = origin.page.allPage;
                  that.allSize=origin.page.allSize
                  that.pageSize = origin.page.pageSize;
                }
              }
            that.showFonts = "未查询到搜索结果数据";
            that.loader = false;
            that.showLoading = false;
            }
      });
      // ajaxPost("getEpsSearchPage", params)
      //   .then(function (res) {
      //     if (res.data) {
      //       if (res.data.success) {
      //         if (res.data.entity) {
      //           //列表数据
      //           res.data.entity.entityList.forEach(function (val, k) {
      //             val.id_check = k + 1;
      //             val.check = false;
      //             val.flagCheck = false;
      //             val.indicatorList.forEach(function (item) {
      //               item.check = false;
      //             });
      //           });
      //           that.timesLists = res.data.entity.entityList;
      //         }
      //       }
      //       that.showFonts = "未查询到搜索结果数据";
      //       that.loader = false;
      //       that.showLoading = false;
      //     }
      //   })
      //   .catch(function (error) {
      //     window.console.log(error);
      //   });
    },
    backTop() {
      // 回到顶部
      let that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.getElementById("searchBar").scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    handleScroll() {
      let that = this;
      //与iview的置顶功能冲突，两者只能展示一个
      const heightVova = 150;
      if (document.getElementById("searchBar").scrollTop > heightVova) {
        this.isScollTable = true;
      } else {
        this.isScollTable = false;
      }
      // 回到顶部
      let scrollTop =
        document.getElementById("searchBar").pageYOffset ||
        document.getElementById("searchBar").scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 0) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
    getUrlParam(key) {
      // 获取参数
      var url = window.location.search;
      // 正则筛选地址栏
      var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
      // 匹配目标参数
      var result = url.substr(1).match(reg);
      //返回参数值
      return result ? decodeURIComponent(result[2]) : null;
    }
  },
  created: function () {},
  mounted() {
    var that = this;
    if (getSessionItem("userData")) {
      that.userObj = getSessionItem("userData");
      that.sid = getSessionItem("userData").sid;
    }
    if (that.$route.params.sName) {
      that.searchName = that.$route.params.sName;
      that.urlName = that.$route.params.sName;
    }
    document
      .getElementById("searchBar")
      .addEventListener("scroll", this.handleScroll);
    document.addEventListener("click", this.hideRec);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.hideRec);
    // document.removeEventListener("click", this.hideHistory);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss">
.search-rec {
  width: 650px;
  .ant-menu-vertical {
    border-right: none;
  }
  .searchText{
    .title{
      font-weight: bold;
      margin-bottom: 10px;
      span{
        float: right;
      }
    }
    ul{
      width: 100%;
      margin-left: 22px;
      margin-bottom: 10px;
      li{
        list-style: none;
        display: inline-block;
        width: 20%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.search-rec2{
  width: 650px;
  // margin-top:
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.demo-spin-col {
  height: 300px;
  position: relative;
}
</style>
<style lang="scss" scoped>
.resItem{
  margin-top: 15px;
  height: 100px;
  border-bottom: 1px solid #cccccc;
  .allName{
    color: #666;
    line-height: 34px;
    display: inline-block;
    width: 900px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .lightColor{
    margin-top: 4px;
    display: flex;
    >span{
      color: #666;
      display: inline-block;
    } 
    .regionName{
      width: 150px;
      span{
        width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        position: absolute;
      }
    }
      .freqName{
        width: 100px;
      }
      .timeName{
        width: 170px;
      }
      .sourceName{
        width: 210px;
        margin-right: 10px;
      }
      .cubeName{
        width:390px;
      }
      .freqName,.timeName,.sourceName,.cubeName{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
  }
}
</style>